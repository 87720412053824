import React from 'react'
import Layout from "../components/layout"
import dinner from '../images/dinner-table.png'
import SEO from '../components/seo'

export default function Article() {
    return (
        <Layout>
            <SEO title="Beatrice and Benedick Spotted on Romantic Dinner Together" image={dinner} />
            <h1>
            Beatrice and Benedick Spotted on Romantic Dinner Together
            </h1>
            <img src={dinner} />
            <p>These past couple weeks have been a proverbial roller coaster of emotions. Surely the ups and downs of scandal and loss have hit all of Messina hard, but none more so than Beatrice, the closest friend and cousin of our late, dear Hero.</p>

            <p>Now, it looks like she may have found someone to help dry her tears.</p>

            <p>After articles and articles from yours truly speculating about the unprofessed attraction between Beatrice and Benedick, the two were finally spotted dining together in public. The usually sparring pair even seemed to enjoy each another’s company.</p> 
        
            <p>“They bantered a little, but in a flirtatious way,” said one eavesdropper who happened to be seated at a nearby table.</p>
        
            <p>Perhaps Hero’s tragedy has forced these once outward foes to face their true feelings for one another.</p>        
        </Layout>
    )
}
